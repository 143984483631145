body {
    background-image: url('/src/background.png');
    background-size: cover;
  }

  body.Guide {
    background-image: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    max-width: 1000px; /* set a maximum width for the container */
    margin: 0 auto; /* center the container horizontally */
    padding: 20px; /* add padding to create gaps between content and border */
  }
  
  