.App {
  font-family: sans-serif;
  text-align: center;
}

.logo {
  border-radius: 10px;
  max-height: 60px;
  cursor: pointer;
}

.nav-link {
  background: transparent;
  color: #000;
  border-radius: 6px;
  cursor: pointer;
}

.nav-link a {
  color: #000;
  text-decoration: underline;
  font-weight: 500;
}

.ml-10 {
  margin-left: 10px;
}

iframe {
  width: 100vw;
  height: calc(100vh - 86px);
  /* height: 200px; 
  width: 100%; */
}

.gap-15 {
  gap: 15px;
}
